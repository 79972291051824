/**
 * Drawer, copied from src/photon/src/contexts/Drawer/index.js to have custom styles
 */
import React, {useState} from 'react'
import Image from 'photon/src/components/Image'
import menuToggle from 'app/theme/assets/menuToggle.svg'
import menuClose from 'app/theme/assets/menuClose.svg'
import {
  DrawerContainer,
  DrawerWrapper,
  DrawerClose,
  DrawerContent,
  DrawerToggle,
} from './styles'

export default ({children}) => {

  const [open, setOpen] = useState(false)

  return (
    <DrawerContainer>
      <DrawerToggle onClick={() => setOpen(true)}>
        <Image src={menuToggle} />
      </DrawerToggle>
      <DrawerWrapper active={open}>
        <DrawerClose onClick={() => setOpen(false)}>
          <Image src={menuClose} />
        </DrawerClose>
        <DrawerContent>{children}</DrawerContent>
      </DrawerWrapper>
    </DrawerContainer>
  );
}
