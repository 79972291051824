import styled, {css} from 'styled-components'
import {getTheme} from 'photon'
import Box from 'photon/src/components/Box'

export const PlaceholderWrapper = styled(Box)
.attrs(props => ({
  className: `photon-placeholder`,
  flex: 1,
}))`
  align-items: center;
  justify-content: center;
  width: ${props => props.w || '100%'};
  height: ${props => props.h || '1em'};

  ${css(getTheme('placeholder'))}
`
