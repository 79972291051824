import React, {useState, useEffect} from 'react'
import Placeholder from '../Placeholder'

export default function PlaceholderImage(props) {
  const {src, ...rest} = props
  const [imageLoaded, setImageLoaded] = useState(false)

  useEffect(() => {
    if (!imageLoaded && src && '' !== src) {
      const _img = new Image();
      _img.onload = () => setImageLoaded(true)
      _img.src = src;
    }
  }, [src, imageLoaded])

  return <Placeholder waitFor={imageLoaded} {...rest} />
}
