import styled from 'styled-components'
import Box from 'photon/src/components/Box'
import Container from 'photon/src/components/Container'
import { respondTo } from 'photon'

export const HeaderWrapper = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: ${props => props.theme.colors.transparent};
  z-index: 1000;
`

export const HeaderInner = styled(Container)`
  padding: .5em;
  justify-content: space-between;
`

export const LogoWrapper = styled(Box)`
  margin-top: 8px;
  margin-left: 8px;

  ${props => respondTo(props.theme.media[0])} {
    margin-top: 24px;
    margin-left: 24px;
  }
`
