import React from 'react';
import Heading from 'photon/src/components/Heading'
import Image from 'photon/src/components/Image'
import { useData } from 'app/lib/hooks/useData'
import {
  FooterTop,
  FooterCol,
  FooterInner,
  FooterWrapper,
  ContactCard,
  FooterIcons,
  CardWrapper,
} from './styles'

function Footer() {

  const site = useData('site')

  const {
    logo_1,
    logo_2,
    headline,
    contact_card,
    // contact_card_2,
    contact_card_1_headline,
    // contact_card_2_headline,
  } = (
    (site && site.options) || {}
  )

  return (
    <FooterWrapper>
      {headline && (
        <FooterTop>
          <FooterCol flexBox={{ flex: 1 }}>
            <Heading
              as={'h3'}
              mt={'0px'}
              mb={'0px'}
              ml={'0px'}
              mr={'0px'}
            >
              {headline}
            </Heading>
          </FooterCol>
        </FooterTop>
      )}
      <FooterInner flex={1}>
        <FooterCol className="card-1">
          <Heading
            as={'h3'}
            mt={'0px'}
            mb={'0.25rem'}
            ml={'0px'}
            mr={'0px'}
          >
            {contact_card_1_headline}
          </Heading>

          <CardWrapper>
            {contact_card &&
              contact_card.map((card, index) => {
              return (
                <div key={index}>
                  <Heading
                    as={'h6'}
                    mt={'0px'}
                    mb={'0.25rem'}
                    ml={'0px'}
                    mr={'0px'}
                  >
                    {card.name}
                  </Heading>
                  <ContactCard mt={'0px'}>
                    {card.position &&
                      <span>{card.position} <br /></span>
                    }
                    {card.email} <br />
                    {card.phone}
                  </ContactCard>
                </div>
              )
            })}
          </CardWrapper>
        </FooterCol>
        {/* <FooterCol className="card-2">
          <Heading
            as={'h3'}
            mt={'0px'}
            mb={'0.25rem'}
            ml={'0px'}
            mr={'0px'}
          >
            {contact_card_2_headline}
          </Heading>

          {contact_card_2 &&
            contact_card_2.map((card, index) => {
            return (
              <div key={index}>
                <Heading
                  as={'h6'}
                  mt={'0px'}
                  mb={'0.25rem'}
                  ml={'0px'}
                  mr={'0px'}
                >
                  {card.name}
                </Heading>
                <ContactCard mt={'0px'}>
                  {card.position &&
                    <span>{card.position} <br /></span>
                  }
                  {card.email} <br />
                  {card.phone}
                </ContactCard>
              </div>
            )
          })}
        </FooterCol> */}
        <FooterIcons>
          <FooterCol widths={['52px', '80px', '84px']}>
            {logo_1 && (
              <Image src={logo_1.url} />
            )}
          </FooterCol>

          <FooterCol widths={['142px', '188px', '200px']}>
            {logo_2 && (
              <Image src={logo_2.url} />
            )}
          </FooterCol>
        </FooterIcons>

      </FooterInner>

    </FooterWrapper>
  );
}

export default Footer
