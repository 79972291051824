import styled from 'styled-components'
import Box from 'photon/src/components/Box'
import Text from 'photon/src/components/Text'
import Container from 'photon/src/components/Container'

import {getTheme, respondTo} from 'photon'

const media = getTheme('media', {})

export const FooterWrapper = styled.footer`
  background-color: rgba(224, 213, 197, .5);
`

export const FooterTop = styled.div`
  padding: 15px 36px 12px 36px;
  border: 0.4px solid #BEB7B0;
  position: relative;

  ${respondTo(media[1])} {
    padding: 38px 86px 32px 86px;
  }

  &:after {
    ${respondTo(media[1])} {
      content: '';
      display: inline-block;
      border-left: solid 1px #301b17;
      height: 113px;
      width: 1px;
      position: absolute;
      top: 0px;
      right: 15%;
    }
  }

  h3 {
    width: 190px;

    ${respondTo(media[1])} {
      width: auto;
      font-size: 36px;
      line-height: 43px;
    }
  }
`

export const FooterInner = styled(Container)`
  padding: 20px 36px 24px 36px;
  justify-content: space-between;
  flex-direction: column;

  & > div {
    margin-bottom: 18px;

    ${respondTo(media[0])} {
      margin-bottom: 26px;
    }
  }

  ${respondTo(media[0])} {
    flex-direction: row;
    justify-content: flex-start;
    padding: 26px 36px 34px 36px;
    flex-wrap: wrap;
  }
  ${respondTo(media[1])} {
    padding: 40px 86px 32px 86px;
  }
  @media only screen and (min-width: 1500px) {
    justify-content: space-between;
  }

  .card-1 {
    flex: 1 1 0%;

    ${respondTo(media[0])} {
      flex: 0 0 100%;
    }
    ${respondTo(media[1])} {
      flex: 0 1 30%;
      margin-right: 6%;
    }
  }
  .card-2 {
    flex: 1 1 0%;

    ${respondTo(media[0])} {
      flex: 0 0 235px;
    }
    ${respondTo(media[1])} {
      flex: 0 1 380px;
    }
  }

  h3 {
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.13;
    letter-spacing: 0.64px;
    margin-bottom: 12px;
    width: 210px;

    ${respondTo(media[1])} {
      font-size: 20px;
      line-height: 1.2;
      letter-spacing: 0.8px;
      width: 100%;
      margin-bottom: 28px;
    }
  }
`

export const FooterCol = styled(Box)`
  h6 {
    margin-bottom: 0px;

    ${respondTo(media[1])} {
      font-size: 25px;
      line-height: 37px;
    }
  }

`

export const ContactCard = styled(Text)`
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.53;
  letter-spacing: normal;
  font-family: 'ChaletLondonNineteenSixty';

  ${respondTo(media[1])} {
    font-size: 19px;
    line-height: 37px;
  }
`

export const FooterIcons = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding-top: 0px;
  padding-bottom: 25px;

  ${respondTo(media[0])} {
    padding: 0px;
    flex-direction: column;
    margin-bottom: 0px;
    margin-top: 46px;
  }
  ${respondTo(media[0])} {
    margin-top: 70px;
  }

  div:first-of-type {
    margin-right: 44px;

    ${respondTo(media[0])} {
      margin-bottom: 16px;
    }
    ${respondTo(media[0])} {
      margin-bottom: 12px;
    }
  }
`
export const CardWrapper = styled.div`
  display: block;

  ${respondTo(media[0])} {
    display: flex;
  }

  & div:first-of-type {
    ${respondTo(media[0])} {
      margin-right: 68px;
    }
    ${respondTo(media[1])} {
      margin-right: 48px;
    }
  }
`
