import React from 'react'
import Image from 'photon/src/components/Image'
import PlaceholderImage from 'photon/src/components/PlaceholderImage'
import { withTheme } from 'styled-components';
import {useData} from 'app/lib/hooks/useData'

function SiteLogo({link, inline, theme}) {
  const site = useData('site')

  const logo = (site && site.logo) || '';

  const echoLogo = () => (
    <PlaceholderImage
      src={logo}
      w={`${theme.wpLogo.width}px`}
      h={`${theme.wpLogo.height}px`}
    >
      <Image {...({src: logo, ...theme.wpLogo})} />
    </PlaceholderImage>
  )

  if (link) {
    return (
      <a href={'/'}>
        {echoLogo()}
      </a>
    );
  }

  return (
    echoLogo()
  );
}

export default withTheme(SiteLogo);
