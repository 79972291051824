import styled from 'styled-components'
import {mediaStyles} from 'photon'

export const TextWrapper = styled.p.attrs(props => ({
  className: `photon-text ${props.className}`
}))`
  color: ${props => props.color || 'inherit'};

  ${props => props.pt && `padding-top: ${props.pt};`}
  ${props => props.pl && `padding-left: ${props.pl};`}
  ${props => props.pr && `padding-right: ${props.pr};`}
  ${props => props.pb && `padding-bottom: ${props.pb};`}

  ${props => props.mt && `margin-top: ${props.mt};`}
  ${props => props.ml && `margin-left: ${props.ml};`}
  ${props => props.mr && `margin-right: ${props.mr};`}
  ${props => props.mb && `margin-bottom: ${props.mb};`}

  /* size or fs = font-size */
  ${props => props.size && (
    Array.isArray(props.size) ?
    mediaStyles('font-size', props.size) :
    `font-size: ${props.size};`
  )}
  ${props => props.fs && (
    Array.isArray(props.fs) ?
    mediaStyles('font-size', props.fs) :
    `font-size: ${props.fs};`
  )}

  /* leading or lh = line-height */
  ${props => props.leading && (
    Array.isArray(props.leading) ?
    mediaStyles('line-height', props.leading) :
    `line-height: ${props.leading};`
  )}
  ${props => props.lh && (
    Array.isArray(props.lh) ?
    mediaStyles('line-height', props.lh) :
    `line-height: ${props.lh};`
  )}
`
