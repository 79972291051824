import React from 'react'

import {
  PlaceholderWrapper
} from './styles'
import Loader from '../Loader'

export default function Placeholder(props) {
  const {waitFor, children, showLoader, ...rest} = props

  if ('undefined' == typeof waitFor
    || null === waitFor
    || false === waitFor) {
    return (
      <PlaceholderWrapper {...rest}>
        {showLoader && <Loader />}
      </PlaceholderWrapper>
    )
  }

  return (<>{children}</>)
}
