import variables from './variables'

export default {
  h1: `
    font-family: ChaletParisNineteenSixty;
    font-size: 35px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 38px;
    letter-spacing: 1.4px;
  `,
  h2: `
    font-family: NewParisHeadline;
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: 0.4px;
  `,
  h3: `
    font-family: ChaletParisNineteenSixty;
    font-size: 25px;
    line-height: 1.875rem;
    letter-spacing: 1px;
  `,
  h4: `
    font-family: NewParisHeadline;
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5rem;
    letter-spacing: 0.4px;
  `,
  h5: `
    font-family: NewParisHeadline;
    font-size: 18px;
    line-height: 1.375rem;
    letter-spacing: 0.36px;
  `,
  h6: `
    font-family: NewParisHeadline;
    font-size: 1em;
    line-height: 1.125rem;
  `,
  p: `
    font-family: ChaletParisNineteenSixty;
    color: ${variables.colors.blackTwo};
  `,
}
