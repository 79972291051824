/**
 * Header
 */
import React from 'react'
import Menu from 'app/lib/components/Menu'
import Drawer from 'app/lib/components/Drawer'
import SiteLogo from 'app/lib/components/SiteLogo'
import {
  HeaderWrapper,
  LogoWrapper,
  HeaderInner,
} from './styles'

function Header(props) {

  return (
    <>
      <HeaderWrapper>
        <HeaderInner flex={1}>
          <LogoWrapper>
            <SiteLogo link />
          </LogoWrapper>
          <Drawer>
            <Menu stacked />
          </Drawer>
        </HeaderInner>
      </HeaderWrapper>
    </>
  );
}

export default Header
