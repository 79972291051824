import { createGlobalStyle, css } from 'styled-components'

export default createGlobalStyle`
  ${props => css`
    ${props.theme.typography}
  `}

  @font-face {
    font-family: 'ChaletLondonNineteenSixty';
    src: url('/assets/font/chaletlondonnineteensixty.woff2') format('woff2'),
      url('/assets/font/chaletlondonnineteensixty.woff') format('woff');
    font-weight: normal;
    font-style: normal;

  }

  @font-face {
    font-family: 'ChaletParisNineteenSixty';
    src: url('/assets/font/chaletparisnineteensixty.woff2') format('woff2'),
      url('/assets/font/chaletparisnineteensixty.woff') format('woff');
    font-weight: normal;
    font-style: normal;

  }

  @font-face {
    font-family: 'NewParisHeadline';
    src: url('/assets/font/newparisheadline.woff2') format('woff2'),
      url('/assets/font/newparisheadline.woff') format('woff');
    font-weight: normal;
    font-style: normal;

  }
`
