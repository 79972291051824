import React from 'react';
import AppRouter from './router'
import './styles/main.scss'
import Header from './parts/Header'
import Footer from './parts/Footer'
import GlobalStyles from './parts/GlobalStyles'
import {ThemeProvider} from 'styled-components'
import photonTheme from 'app/theme'

function App() {

  return (
      <ThemeProvider theme={photonTheme}>
        <GlobalStyles />
        <Header />
        <AppRouter />
        <Footer />
      </ThemeProvider>
  );
}

export default App;
